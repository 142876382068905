.contact-contain {
  display: grid;
  justify-items: center;
  grid-template-columns: 720px auto;
  grid-template-rows: 130px auto;
}

.contact-contain > img {
  width: 320px;
  grid-row: 1 / 3;
  align-self: center;
  grid-column: 2;
  margin: 32px 0 0;
}

.subcontacts {
  display: flex;
  align-items: center;
  width: fit-content;
  grid-row: 2;
  flex-direction: column;
}

.subc {
  width: fit-content;
}

.subc > span {
  font-family: 'Times New Roman', Times, serif;
  font-size: 28px;
  text-align: center;
}

.subcontacts > p {
  font-family: 'Times New Roman', Times, serif;
  font-style: italic;
  font-size: 20px;
  text-align: center;
  width: 500px;
}