html {
  background-color: #c2b2a3;
  display: grid;
  justify-content: center;
}

body.noscroll {
  overflow: hidden;
}

.HOC {
  background-color: #fdfdfd;
  width: 1100px;
  height: 100%;
}

.hoc-children {
  min-height: calc(100vh - 341px);
}

/* a > img {
  padding: 2px;
}

a > img:hover {
  cursor: pointer;
  padding: 0;
  border: 2px solid #b9534588;
  border-radius: 10px;
} */

@media screen and (max-width: 800px) {
  .videos-contain .vid-block {
    width: fit-content;
    flex-direction: column;
  }

  .videos-contain .vid-block .VideoElem {
    margin: 0 0 48px;
  }

  .HOC .contact-contain {
    grid-template-columns: 100%;
  }

  .HOC .contact-contain > img {
    grid-column: 1;
    grid-row: 3;
  }

  .HOC .subcontacts {
    margin: 24px 0;
  }
}