.ob-contain {
  display: grid;
  grid-template-columns: 50% 50%;
}

.ob-contain .Title {
  grid-column: 1 / 3;
  margin-bottom: 48px;
}

.BookDisplay {
  display: grid;
  justify-items: center;
  grid-column: 1;
  margin-bottom: 64px;
  height: fit-content;
}

.BookDisplay > p {
  font-size: 16px;
}

.BookDisplay > h3, .BookDisplay > p {
  display: inline-block;
  font-family: 'Times New Roman', Times, serif;
  font-weight: 400;
  margin: 4px 16px;
  max-width: 394px;
  text-align: center;
}

.BookDisplay .blurb {
  font-style: italic;
}

.BookDisplay .pubInfo {
  margin-bottom: 24px;
}

.BookDisplay > h3 {
  font-size: 28px;
  font-weight: 600;
  color: #b95345;
  font-style: italic;
}

.BookDisplay > img {
  width: 384px;
}

.OtherBooks .download-contain {
  border: none;
}

.OtherBooks .book-desc {
  font-size: 16px;
  font-family: 'Times New Roman', Times, serif;
  margin: 0 124px 96px 0;
  align-self: flex-end;
}


.book-desc p, .book-desc i {
  text-align: justify;
}

.OtherBooks .subt {
  font-style: italic;
  text-align: center;
}

.OtherBooks .author {
  text-align: right;
}

.OtherBooks .hr {
  margin: 8px 96px 48px;
  height: 0px;
  border-top: 2px solid #b95345;
  grid-column: 1 / 3;
}

.OtherBooks .author, .OtherBooks .subt, .OtherBooks h4 {
  margin-bottom: 32px;
}