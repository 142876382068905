.review-contain {
  display: grid;
  justify-items: center;
}

.review-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 800px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  text-align: justify;
  margin-top: 16px;
}

.Review p {
  margin: 24px 0;
}

.Review .review-blurb {
  margin: 0;
  font-style: italic;
}

.Review .reviewer {
  margin: 0;
  font-weight: bold;
}

.Review .auth-desc {
  width: 550px;
  text-align: right;
  margin: 0;
}

.Review .auth-desc > p {
  margin: 0;
}

.Review hr {
  border: 0;
  border-top: 2px solid #b95345;
  width: 50%;
  margin: 24px 0;
}

.review-clicker {
  color: #b95345;
}

.review-clicker:hover {
  cursor: pointer;
}

.bkgd-blocker {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  display: grid;
  transition-property: opacity;
  transition-duration: 0.15s;
}

.bkgd-blocker.show-blocker {
  visibility: visible;
  opacity: 1;
}

.bkgd-blocker.fade {
  opacity: 0;
}

.ReviewFull {
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  background-color: rgb(246 246 246);
  margin: 48px max(64px, calc((100vw - 1100px) / 2 + 76px));
  height: calc(100vh - 80px);
  border-radius: 4px;
  overflow-y: scroll;
}

.ReviewFull .hrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  height: fit-content;
}

.ReviewFull h3 {
  color: #b95345;
  font-size: 32px;
  font-weight: 400;
  margin: 24px 32px;
}

.ReviewFull img {
  margin: 0 24px;
  padding: 8px;
  height: 12px;
  grid-row: 1;
}

.ReviewFull img:hover {
  cursor: pointer;
}

.ReviewFull .rauth h4, .ReviewFull .rauth p {
  margin: 8px;
}
.ReviewFull .rauth {
  margin: 16px 24px 0;
}

.ReviewFull .rbody {
  margin: 0 48px;
}

.ReviewFull .rbody > h4:first-child {
  margin-left: -16px;
}

.ReviewFull .ol {
  margin: -8px 0 32px 32px;
}

.ReviewFull .ol h4 {
  margin: 42px 0 12px;
}

.ReviewFull .rbody li {
  margin: 12px 32px;
}

.ReviewFull .reviewer-info {
  margin: 16px 0 48px;
  text-align: right;
  width: 620px;
  margin-left: 188px;
}

.ReviewFull .reviewer-info p, .ReviewFull .reviewer-info h4 {
  margin: 0;
}


.ReviewFull .frow {
  border-top: 1px solid #dddddd;
  height: 85px;
  display: grid;
  justify-content: flex-end;
  align-items: center;
}

.ReviewFull .close-btn {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  height: fit-content;
  border-radius: 4px;
  padding: 16px 24px;
  color: rgb(246 246 246);
  background-color: #b95345;
  text-transform: uppercase;
  margin-right: 16px;
}

.ReviewFull .close-btn:hover {
  cursor: pointer;
}