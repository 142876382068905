.videos-contain {
  display: grid;
  justify-items: center;
}

.videos-contain .Title {
  margin-bottom: 48px;
}

.videos-contain .VideoElem {
  margin: 0 0 24px;
}

.videos-contain .VideoElem > iframe {
  width: 480px;
  height: 270px;
}

.yt-blurb {
  display: grid;
  justify-items: center;
  font-size: 28px;
  font-weight: 600;
}

.yt-blurb img {
  width: 192px;
  border-radius: 8px;
}

.vid-block {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}