
  
  #body {
    display: grid;
    grid-template-rows: 192px 60px 354px auto;
    grid-template-columns: 698px auto;
  }

  .Home .VideoElem {
    grid-row: 3;
    grid-column: 1;
  }
  
  .cover-contain {
    grid-row: 1 / 4;
    grid-column: 2;
    justify-self: center;
  }
  
  #book-cover {
    width: 354px;
    margin: 40px 40px 0 0;
    margin-left: 0;
    border: 4px solid #dddddd;
    border-radius: 8px;
  }
  
  .downloads {
    margin: 0 36px;
    grid-row: 2;
    display: flex;
    height: fit-content;
    justify-items: center;
    justify-self: center;
  }
  .download-contain {
    width: fit-content;
    height: fit-content;
    border-right: 2px solid #b95345;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
  }
  
  .download-contain:last-child {
    border-right: none;
  }
  
  .download-contain > a {
    font-family: 'Times New Roman', Times, serif;
    text-decoration: none;
    color: #000000;
    margin: 0 16px;
    padding: 8px 0;
    font-size: 18px;
    text-transform: uppercase;
    text-wrap: nowrap;
    transition-property: color, border;
    transition-duration: 0.2s;
    transition-timing-function: ease;
  }
  
  
  .download-contain > a:hover { 
    color: #b95345;
    border-bottom: 2px solid #b95345;
  }
  .download-contain > img {
    width: 14px;
    height: 16px;
    margin-right: 12px;
  }
  
  .new-tab {
    width: 14px;
  }
  
  .subcover {
    grid-column: 1 / 3;
    grid-row: 5;
    justify-self: flex-end;
    margin-right: 66px;
    margin-top: 16px;
    display: grid;
    height: fit-content;
    width: 300px;
    justify-items: center;
    text-align: center;
  }
  
  .subcover > span {
    font-size: 16px;
    font-family: 'Times New Roman', Times, serif;
    align-self: flex-end;
    margin: 8px 32px;
  }
  
  .subcover img {
    height: 72px;
    border-radius: 8px;
  }

  .subcover a {
    margin: 8px 0;
    display: grid;
  }
  
  .note-container {
    margin: -245px 416px 0 54px;
    grid-column: 1 / 3;
    grid-row: 5 / 7;
    border-top: 2px solid #b95345;
    text-align: justify;
  }
  
  .note-container > p, .note-container > h2 {
    margin: 16px 8px;
    font-weight: 400;
    font-family: 'Times New Roman', Times, serif;
    line-height: 1.3;
    color: #000000;
  }
  
  .note-container > h2 {
    font-size: 32px;
    font-style: italic;
    margin: 16px 8px;
    text-align: center;
  }
  
  .note-container > p {
    font-size: 20px;
  }
  
  #pub-logo {
    width: 248px;
  }

  .pub {
    grid-row: 4;
    grid-column: 2;
    display: grid;
    justify-items: center;
    grid-template-columns: auto;
    margin: 24px 26px 0 0px;
  }

  .pub > h4, .pub > p {
    text-align: center;
    margin: 0;
    font-size: 16px;
    font-family: 'Times New Roman', Times, serif;
  }

  .pub > h4, .pub > p, .pub img {
    grid-column: 1;
  }
  
  .pub a {
    margin-left: 8px;
  }