

footer {
    background-color: #2f2f2f;
    height: 72px;
    display: grid;
    align-items: center;
    margin-top: 48px;
  }
  
  footer > span {
    font-size: 16px;
    font-family: 'Times New Roman', Times, serif;
    color: #ffffff;
    margin: 0 48px;
  }