.Title {
  margin: 32px 48px;
}


.Title {
  border-bottom: 2px solid #b95345;
  height: fit-content;
  width: fit-content;
  justify-self: center;
  padding: 0 48px 8px;
  grid-row: 1;
  grid-column: 1;
  text-align: center;
}

.Title > h1, .Title > h2 {
  margin: 8px;
  font-weight: 400;
  font-family: 'Times New Roman', Times, serif;
  color: #b95345;
  display: inline-block;
}

.Title > h1 {
  font-size: 40px;
  text-transform: uppercase;
}

.Title > h2 {
  margin: 0 8px 8px;
  font-size: 32px;
}

.Title.main-title {
  /* text-align: center; */
  width: fit-content;
  justify-self: flex-start;
  /* padding: 0 116px 8px 0; */
}

.Title.main-title > h1 {
  font-weight: 600;
  margin: 0;
  font-size: 72px;
  text-transform: none;
  /* font-style: italic; */
}

.Title.main-title > h2 {
  margin: 0;
  margin-bottom: 8px;
  font-size: 28px;
  text-transform: none;
  font-style: italic;
}