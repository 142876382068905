header {
    height: 137px;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: #b95345;
  }
  
  header > img {
    width: 600px;
  }