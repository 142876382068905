.NavBar {
    height: 84px;
    border-bottom: 1px #dddddd solid;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  
  .navButton, .navButton-hidden {
    visibility: visible;
    display: grid;
    justify-content: center;
    align-items: center;
    padding-top: 3px;
    margin-top: 1px;
    z-index: 2;
    text-wrap: nowrap;
  }
  
  .navButton-hidden {
    border-top: 3px #b95345 solid;
    padding-top: 0;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    position: absolute;
    z-index: 3;
  }
  
  .navButton > span, .navButton-hidden > span {
    font-family: "Times New Roman", Times, serif;
    color: #000000;
    text-transform: uppercase;
    font-size: 20px;
    margin: 28px 0;
  }
  
  .navButton-hidden > span {
    color: #b95345;
    text-decoration: none;
  }
  
  .navButton-hidden:hover, .navButton-hidden.shown {
    opacity: 1;
    cursor: pointer;
  }