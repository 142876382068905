.about-contain {
  display: grid;
  grid-template-columns: 720px auto;
}

.Title {
  grid-row: 1;
  grid-column: 1;
}

.about-text {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
  grid-row: 2;
  margin: 0 64px;
  text-align: justify;
}

.about-contain > img {
  width: 320px;
  grid-row: 2;
  align-self: center;
  /* margin: 48px 0; */
}